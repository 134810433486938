<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }"
                    >首页
                    </el-breadcrumb-item
                    >
                    <el-breadcrumb-item>系统</el-breadcrumb-item>
                    <el-breadcrumb-item>权限管理</el-breadcrumb-item>
                    <el-breadcrumb-item>角色列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="角色名称">
                            <el-input
                                    v-model="where.obj.roleName"
                                    placeholder="请输入角色名称"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                            >条件筛选
                            </el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>
                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="showDailog({})"
                                    icon="el-icon-plus"
                            >新增
                            </el-button
                            >
                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    disabled
                                    icon="el-icon-tickets"
                            >导出数据
                            </el-button
                            >
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="roleName" label="角色名称">
                        <template slot-scope="scope">
                            <el-link type="primary" @click="showDailog(scope.row)">{{scope.row.roleName}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注"></el-table-column>
                    <el-table-column prop="createDate" label="创建时间"></el-table-column>
                    <el-table-column label="操作" fixed="right" width="270px">
                        <template slot-scope="scope">
                            <!--<el-link style="margin-left: 10px" type="primary" @click="addPower = true">添加管理员</el-link>-->
                            <el-link style="margin-left: 10px" type="primary" @click="addPowerAction(scope.row)">权限设置
                            </el-link>
                            <el-link style="margin-left: 10px" type="danger" @click="deleteBtn(scope.row)">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <info :row="row" @dialog="getDialog" v-if="dialogDetailVisible"></info>
            <edit :row="row" @dialog="getDialog" v-if="dialogEditVisible"></edit>
            <power :row="row" :visible="addPowerDialogVisible" @dialog="getDialog"></power>
        </div>
    </div>
</template>

<script>
    import info from "@/components/admin/permission/role/check.vue";
    import edit from "@/components/admin/permission/role/set.vue";
    import power from "@/components/admin/permission/role/power.vue";

    export default {
        components: {
            info,
            edit,
            power
        },
        props: {
            state: Number,
        },
        data() {
            return {
                list: [],
                date: "",
                row: {},
                loading: false,
                dialogDetailVisible: false,
                dialogEditVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                rolesData: [],
                selectIds: [],
                where: {
                    pageNum: 0,
                    pageSize: 0,
                    obj: {}
                },
                addPowerDialogVisible: false,
                addPower: false,
                selectedUser: [],
                multipleSelection: []
            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.$post(this.$api.roleList, this.where).then((res) => {
                    this.list = res.data.records;
                    this.loading = false;
                });
            },
            exportData() {
                // console.log("exportData");
            },
            getDialog(val) {
                this.dialogDetailVisible = val;
                this.dialogEditVisible = val;
                this.addPowerDialogVisible = val;
            },
            showDailog(data) {
                this.row = data;
                this.dialogEditVisible = true;
            },
            deleteBtn(data) {
                this.row = data;
                this.dialogDetailVisible = true;
            },
            addPowerAction(data) {
                this.row = data;
                this.addPowerDialogVisible = true;
            }
        },
        created() {
            this.listData();
        },
    };
</script>
<style lang="scss" scoped>
    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }

    .el-select {
        width: 120px;
    }
</style>
<style>
    .el-drawer__header span:focus {
        outline: 0 !important;
    }
</style>
