<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogSendVisible"
    width="50%"
    :before-close="handleSendClose"
  >
    <el-timeline>
      <el-form
        :model="ruleForm"
        status-icon
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="span">
            <el-form-item
              label="角色名"
              prop="roleName"
              :rules="[
                { required: true, message: '请输入角色名', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model.number="ruleForm.roleName"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="ruleForm.remark" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-timeline>
  </el-dialog>
</template>
<script>
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      span: 12,
      title: "新增",
      dialogSendVisible: true,
      rolesData: "",
      disabled: false,
      ruleForm: {
        roleName: "",
        remark:""
      },
      activeName: 'first',
        activeNameOfPower:'third',
        adminRoles:[]
    };
  },
  methods: {
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const postData = this.ruleForm;
          postData.id = this.row.id;
          let url = this.$api.roleSet;
          this.post(url, postData);
        } else {
          return false;
        }
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.$parent.listData();
          this.handleSendClose();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    if (this.row && this.row.id) {
      this.title = "编辑";
      this.ruleForm.roleName = this.row.roleName;
      this.ruleForm.remark = this.row.remark;
      this.disabled = true;
    }
  },
    watch:{
      disabled(){
          if(this.disabled){
              // this.resetAdminRoleList();
          }
      }
    }
};
</script>
